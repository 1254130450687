<template>
  <v-dialog
    :value="true"
    persistent
    max-width="500px">
    <v-card>
      <validation-observer
        ref="saleTagForm"
        tag="form"
        @submit.prevent="onSubmit()">
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="formData.tag"
                  :items="tags"
                  item-text="name"
                  return-object
                  label="Tag" />
              </v-col>
              <v-col cols="6">
                <div class="d-flex flex-row justify-center align-center">
                  <span>Percentage</span>
                  <v-switch
                    v-model="formData.type"
                    class="ml-2"
                    true-value="bath"
                    false-value="percentage"
                    dense />
                  <span>Bath</span>
                </div>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model.number="formData.discount"
                  :rules="requiredRule"
                  type="number"
                  label="Discount" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="onClose()">
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            type="submit"
            text>
            Submit
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import { mapActions } from 'vuex'

const ProductAttributeService = new ProductAttributeProvider()

export default {
  props: {
    formTitle: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      tags: [],
      requiredRule: [
        (v) => !!v || 'Field is required!'
      ]
    }
  },
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    this.getTags()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    async getTags () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'Getting Tags...'
        })

        const { data } = await ProductAttributeService.getProductAttribute('tags', {
          page: 1,
          itemsPerPage: 9999999,
          sortBy: ['id'],
          sortDesc: [true]
        })
        this.tags = data.results.map((r) => ({
          id: r.id,
          name: r.name,
          status: 'active'
        }))
      } catch (error) {
        console.error('getTags: ', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({
          active: false,
          clickAble: false,
          message: 'Getting Tags...'
        })
      }
    },
    async onSubmit () {
      const valid = await this.$refs.saleTagForm.validate()

      if (valid) {
        this.$emit('on-submit')
      }
    },
    onClose () {
      this.$emit('on-close')
    }
  }
}
</script>
