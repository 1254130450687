import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class SaleTagsProvider extends HttpRequest {
  getSaleTags (query) {
    this.setHeader(getAuthToken())
    return this.get('sale-tags', query)
  }

  createSaleTag (saleTagForm) {
    this.setHeader(getAuthToken())
    return this.post('sale-tags', saleTagForm)
  }

  updateSaleTag (saleTagForm) {
    this.setHeader(getAuthToken())
    return this.patch(`sale-tags/${saleTagForm.id}`, saleTagForm)
  }

  deleteSaleTag (id) {
    this.setHeader(getAuthToken())
    return this.delete(`sale-tags/${id}`)
  }
}

export default SaleTagsProvider
