<template>
  <div>
    <v-data-table
      :headers="header"
      :items="saleTags"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      class="elevation-1 mb-5"
      hide-default-footer>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize">
              Sale Tags
            </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <div class="d-flex flex-row justify-end align-base-line">
            <search-box
              v-model="options.search"
              @on-search="getSaleTags(true)" />
            <v-btn
              class="ml-2"
              color="secondary"
              @click="showForm()">
              New Sale Tag
            </v-btn>
          </div>
        </v-toolbar>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.tag.name }}
      </template>
      <template v-slot:[`item.discount`]="{ item }">
        {{ `${item.discount}${getDiscountType(item.type)}` }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <gw-icon-hover
          small
          class="mr-2"
          icon-name="mdi-pencil"
          icon-hover="mdi-pencil"
          @click="editSaleTag(item)" />
        <gw-icon-hover
          small
          class="sku-table-bin"
          icon-name="mdi-delete"
          icon-hover="mdi-delete-empty"
          @click="removeSaleTag(item)" />
      </template>
    </v-data-table>
    <v-pagination
      v-model="options.page"
      :length="pageCount"
      :disabled="loading"
      total-visible="7" />

    <sale-tag-form
      v-if="dialog"
      v-model="formData"
      :form-title="formTitle"
      @on-submit="onSubmit()"
      @on-close="onClose()" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import SaleTagForm from '../components/SaleTagForm.vue'
import SaleTagsProvider from '../../../resources/SaleTagsProvider'

const SaleTagsService = new SaleTagsProvider()

export default {
  components: { SearchBox, SaleTagForm },
  data () {
    return {
      dialog: false,
      loading: false,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 20,
        search: ''
      },
      saleTags: [],
      header: [
        {
          text: 'Name',
          value: 'name',
          width: 200
        },
        {
          text: 'Type',
          value: 'type',
          width: 200
        },
        {
          text: 'Discount',
          value: 'discount',
          width: 200
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 60,
          align: 'center'
        }
      ],
      isEdited: false,
      formData: {
        tag: {
          id: null,
          name: ''
        },
        discount: 0,
        type: 'bath'
      }
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Item' : 'New Item'
    }
  },
  mounted () {
    if (this.options.search === '') {
      this.options.search = this.$route.query?.search || ''
    }
    this.getSaleTags(true)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    async getSaleTags (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.options.page = 1
        }

        const { data } = await SaleTagsService.getSaleTags({
          page: this.options.page,
          limit: this.options.itemsPerPage,
          search: this.options.search
        })

        this.saleTags = data.results
        this.pageCount = data.pages
      } catch (error) {
        console.error('getSaleTags: ', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'Loading...'
        })

        if (this.isEdited) {
          await SaleTagsService.updateSaleTag(this.formData)
        } else {
          await SaleTagsService.createSaleTag(this.formData)
        }

        this.onClose()
        this.getSaleTags(true)
      } catch (error) {
        console.error('onSubmit: ', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({
          active: false,
          clickAble: false,
          message: 'Loading...'
        })
      }
    },
    removeSaleTag (item) {
      this.setModal({
        value: true,
        title: 'Remove Sale Tag',
        message: `Do you want to remove ${item.name} tag?`,
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveSaleTag(item.id)
      })
    },
    async confirmRemoveSaleTag (id) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'Loading...'
        })

        await SaleTagsService.deleteSaleTag(id)

        this.getSaleTags(true)
      } catch (error) {
        console.error('confirmRemoveSaleTag: ', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({
          active: false,
          clickAble: false,
          message: 'Loading...'
        })
      }
    },
    getDiscountType (type) {
      return (type === 'bath') ? '฿' : '%'
    },
    showForm () {
      this.isEdited = false
      this.formData = {
        tag: {
          id: null,
          name: ''
        },
        discount: 0,
        type: 'bath'
      }
      this.dialog = true
    },
    onClose () {
      this.dialog = false
      this.isEdited = false
      this.formData = {
        tag: {
          id: null,
          name: ''
        },
        discount: 0,
        type: 'bath'
      }
    },
    editSaleTag (item) {
      this.isEdited = true
      this.formData = { ...item }
      this.dialog = true
    }
  }
}
</script>

<style scoped>

</style>
